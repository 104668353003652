import 'prop-types';
import withConformsTo from '@dbh/with-conforms-to-for-production-www';
import Sentry from '@dbh/sentry';
import invariant from 'invariant';
import _isPlainObject from 'lodash/isPlainObject';
import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import _isFinite from 'lodash/isFinite';
import _isNumber from 'lodash/isNumber';
import isValidDate from 'date-fns/isValid';
import 'prop-types-exact';
import 'check-prop-types';
import { Map, List, isImmutable } from 'immutable';
import { valuesSpacing } from '@dbh/theme-values';
import throwInServerSideRendering from '@dbh/throw-in-server-side-rendering';

/*
 *
 * `isValidEmail`: `@dbh/validation`.
 *
 */ // eslint-disable-next-line no-useless-escape
const REGEX_EMAIL_VALIDATION=/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,isValidEmail=a=>// We check that `maybeEmail` is a `string` because there are values that
// cannot be converted to string (@example `Object.create(null)`) that
// would raise an exception.
"string"==typeof a&&REGEX_EMAIL_VALIDATION.test(a);/**
 * Returns `true` if the input provided is a valid email.
 * @param {string?} maybeEmail The value to be tested.
 * @return {boolean} `true` if the email is valid.
 */var isValidEmail$1 = isValidEmail;

const flags=["i","g","m","s","u","y"],invalidResult={isValid:!1,regExp:void 0},getRegExpFromString=withConformsTo("getRegExpFromString",[],a=>{// A minimum valid regular expression has at least three characters.
// @example `/./`.
if(!a||3>a.length)return invalidResult;const b=a.startsWith("/"),c=a.endsWith("/"),d=a.split("/").filter(Boolean),e=d.length;if(!e)return invalidResult;const f=e-1,g=1<e?d[f]:void 0,h=!!g&&!c&&flags.some(a=>g.toLowerCase().includes(a)),i=h?d.splice(f):void 0;if(b&&(c||h))try{const a=new RegExp(d.join("/"),i);return {isValid:!0,regExp:a}}catch(a){return invalidResult}return invalidResult});/**
 *
 * @typedef {{
 * isValid: boolean,
 * regExp?: RegExp
 * }} GetRegExpFromStringResult .
 */ /**
 * Parses the string, and if it contains a valid regular expression,
 * it returns it as an instance of `RegExp`.
 * @param {string} inputValue .
 * @return {GetRegExpFromStringResult} .
 */var getRegExpFromString$1 = getRegExpFromString;

/**
 * Reports the given message or `Error` to `sentry` and prints a console error.
 * The message printed to the console is generic in `www.daybreakhotels.com`,
 * for privacy.
 * @param {boolean} condition If truthy we don't report or log the message.
 * @param {string|Error} messageOrError .
 */const invariantErrorLog=(a,b)=>{if(a)return;const c="Internal error.";// eslint-disable-next-line no-console
console.error(c),"object"==typeof b?Sentry.captureException(b):Sentry.captureMessage(b);};var invariantErrorLog$1 = invariantErrorLog;

/**
 * Prints well formatted console errors for groups of invalid values.
 * Doesn't throw.
 * @param {Immutable.List<Immutable.Map>} invalidGroup Contains items with invalid values.
 * @param {string} key The key of the invalid value.
 * @param {string} message Error message shown as the header of the error group.
 */const consoleWarningGroup=()=>{};/**
 * Doesn't throw, but outputs a `console.error` message and notifies `sentry`.
 * @param {boolean} condition If not satisfied, print error message.
 * @param {string} message Error description.
 */const invariantWarning=(a,b)=>{invariant(!!b,"(`invariantWarning`) `"+b+"`."),a||(Sentry.captureException(Error(b)),console.error(b));};

/**
 * Logs the given error to `sentry` and in the console. In production (`www.`)
 * it does not log in the console, but just sends the error to `sentry`.
 * @param {Error} error .
 */const logPropTypeError=a=>{console.error("Internal validation error."),Sentry.captureException(a);};/**
 * Same as `exactShape`, but will never throw and interrupt the flow in
 * production (`https://www.daybreakhotels.com`).
 * @param {Object?} sourcePropTypes If it is not exist or if it is falsy, it is
 * a programming error. To save bundle size in production, we use `{}`.
 * @return {Object?} The return value of `exactShape`.
 * @throws When `exactShape` would throw, but never throws in production.
 */ // eslint-disable-next-line @dbh/restricted-usage/no-restricted-declaration
/**
 * Validates `source` with `prop-types`.
 * @param {any} source .
 * @param {Object} sourcePropTypes .
 * @param {string} sourceName .
 * @throws If `sourcePropTypes` is not an `Object`, or if the props found
 * in `source` are not valid.
 */const conformsTo=()=>{};/**
 * Validates `source` with `prop-types`.
 * @param {any} source .
 * @param {Object} sourcePropTypes .
 * @param {string} sourceName .
 * @throws If `sourcePropTypes` is not an `Object`, or if `source` contains
 * props not defined in `sourcePropTypes` and as such unexpected. Or if the
 * expected props found in `source` are not valid.
 */const conformsToExact=()=>{};// The `Warning` versions are particularly useful in `SAGAs`, since throwing
// sometimes breaks the navigation. Maybe what has been changed in the API is a
// property that is not required in the current saga, so interrupting the flow by
// throwing may be too strict. It is NOT recommended to ignore these errors though,
// a name change or the removal of a property from a `JSON` can break other parts
// or pages or logic of the Front End, even if it's not visible in the current
// page/view/saga you are working on.
const conformsToExactWithoutThrow=function(){try{return conformsToExact(...arguments),!0}catch(a){return logPropTypeError(a),!1}};/**
 * Make a validator that is also executed on `www.daybreakhotels.com`. Use this
 * only when `fnValidate` is "fast", otherwise you can slow down the website.
 * @param {Function} fnValidate Must return `true` if its argument is valid, or false.
 * @param {string?} expectedType The expected "type", it is used only in the error
 * messages. @example `Immutable.Map`.
 * @return {Function} Calls `fnValidate` with its first argument `parameterToValidate`
 * and throws if `fnValidate` returns false. Its second and third argument are
 * only used in the `invariant` error messages (when `fnValidate` returns false).
 */const createValidator=withConformsTo("createValidator",[],(a,b)=>withConformsTo("validator created by createValidator",[],(c,d,e,f)=>{const g=d||"the given parameter";return invariant(a(c,g,e),(e?"(`"+e+"`) ":"")+"Expected `"+g+"` to be a valid: `"+b+"`, instead "+("given: `"+("object"==typeof c?c:c)+"`."+(f?"\n"+f:""))),!0}));/**
 * Make a validator that is executed everywhere but not on "www.daybreakhotels.com".
 * Note: (the line that calls `createValidator` is stripped in
 * `yarn build:production-www`), so you can use this to create validators when
 * `fnValidate` is a little slower, compared to the ones you create directly with
 * `createValidator`.
 * @param {Function} fnValidate @see `createValidator`.
 * @param {string} expectedType @see `createValidator`.
 * @return {Function} @see `createValidator`.
 */const createValidatorNotExecutedInWWW=()=>()=>!0;// `Immutable` and primitive types validators.
// @example
// ```js
// function doSomething(someValue, otherValue) => {
//    // ...
//    validateImmutableMap(someValue, 'someValue', 'doSomething');
//    // ...
// }
// ```
// The code above, if `someValue` is not an `Immutable.Map`, throws with:
// `Error: (`doSomething`) Expected `someValue` to be a valid: `Immutable.Map`.`.
const validateImmutableMap=createValidator(Map.isMap,"Immutable.Map");const validateImmutableList=createValidator(List.isList,"Immutable.List");const validateImmutableListOrArray=createValidator(a=>List.isList(a)||Array.isArray(a),"Immutable.List|Array");/**
 * Raises an exception if the first argument is not an `Array`.
 * @param {any} maybeNumber Input to be validated.
 * @throws If input is not valid.
 */const validateNumber=createValidator(_isNumber,"Number");/**
 * Raises an exception if the first argument is not a finite number,
 * a number that is not `NaN`, `+Infinity` or `-Infinity`.
 * @param {any} maybeFiniteNumber Input to be validated.
 * @throws If input is not valid.
 */const validateFiniteNumber=createValidator(_isFinite,"Finite number(not NaN, +Inifinity or -Infinity)");/**
 * Raises an exception if the first argument is not a positive number or `0`,
 * a number that is not `NaN` or `-Infinity`.
 * @param {any} number Input to be validated.
 * @throws If input is not valid.
 */const validateNonNegativeNumber=createValidator(a=>_isNumber(a)&&0<=a,"A number `>= 0`.");const validateString=createValidator(_isString,"String");const validateNonBlankString=createValidator(a=>!!_isString(a)&&!!a.trim(),"non blank String");const validateFunction=createValidator(a=>a&&"function"==typeof a,"Function");const validateTruthyDoNotUseForNumbers=createValidator(a=>!!a,"\"truthy\" value");const validateFalsyDoNotUseForNumbers=createValidator(a=>!a,"\"falsy\" value");const validateDate=createValidator(isValidDate,"date");const validateDateString=createValidatorNotExecutedInWWW();/**
 * Raises an exception if the first argument is not an `Array`.
 * @param {any} maybeArray Input to be validated.
 * @throws If input is not valid.
 */const validateArray=createValidator(Array.isArray,"Array");/**
 * Raises an exception if the first argument is not an `Object`.
 * @param {any} maybeObject Input to be validated.
 * @throws If input is not valid. Also throws for `Immutable` `objects`.
 */const validateObject=createValidator(a=>_isObject(a)&&!isImmutable(a),"Object");/**
 * Raises an exception if the first argument is not a "plain" `Object`.
 * @param {any} maybePlainObject Input to be validated.
 * @throws If input is not valid.
 */const validatePlainObject=createValidator(_isPlainObject,"plain `Object`");/**
 * Test whether the string is a valid git commit hash.
 * @see {@link https://git-scm.com/book/en/v2/Git-Tools-Revision-Selection}
 * @param {string} maybeCommitHash .
 * @throws If not a valid commit hash.
 */const validateCommitHash=createValidator(a=>/^[a-f0-9]{7,40}$/i.test(a),"`GIT` commit hash");/**
 * Returns `true` if `source` is a `Promise`.
 * "The resolve function returns either a new promise resolved with the passed
 * argument, or the argument itself if the argument is a promise produced by
 * this constructor.".
 * @see {@link http://www.ecma-international.org/ecma-262/6.0/#sec-promise.resolve}
 * @param {any} source .
 * @return {boolean} Is `source` a `Promise`.
 */const isPromise=a=>Promise.resolve(a)===a;const validatePromise=createValidator(a=>isPromise(a),"`Promise`");/**
 * Raises an exception if `keyPathToValidate` is not a valid `key path` in `window`.
 * @param {Object|Immutable.Map} collection The target where the `key path` will be checked.
 * @param {string[]} keyPathToValidate Keys that can be traversed in `collection`.
 * @param {string[]} paramName A textual representation of `keyPathToValidate`.
 * @param {string?} functionName The name of the function that is using the validator.
 * @return {boolean} Is the `key path` valid.
 * @throws If the `key path` is invalid.
 */const validateWindowKeyPath=createValidator((a,b,c)=>{throwInServerSideRendering("validateWindowKeyPath"),validateArray(a,b,c);let d=0,e=window[a[d]];for(;d!==a.length;){if(!e)return !1;d+=1,e=e[a[d]];}return !0},"a valid `key path` in `window`");/**
 * Checks if the given `value` is one of `spacing` values i.e `sp3`, `0`.
 *
 * @param {string|number} value .
 * @return {boolean} .
 */const isValidSpacingValue=a=>0===a||"0"===a||valuesSpacing.includes(a);

export { conformsTo, conformsToExact, conformsToExactWithoutThrow, consoleWarningGroup, createValidator, getRegExpFromString$1 as getRegExpFromString, invariantErrorLog$1 as invariantErrorLog, invariantWarning, isPromise, isValidEmail$1 as isValidEmail, isValidSpacingValue, validateArray, validateCommitHash, validateDate, validateDateString, validateFalsyDoNotUseForNumbers, validateFiniteNumber, validateFunction, validateImmutableList, validateImmutableListOrArray, validateImmutableMap, validateNonBlankString, validateNonNegativeNumber, validateNumber, validateObject, validatePlainObject, validatePromise, validateString, validateTruthyDoNotUseForNumbers, validateWindowKeyPath };
